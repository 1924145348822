import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IUser, ISaveDeleteResponse, IUserList, IUserNameExistModel, IUserLicenseExceeds, IApiResponse, IThirdPartyUserModel, IMFASettings, ISaveUserResponse, IRiskyUser } from '../Shared/interface';
import { environment } from 'src/environments/environment';
import { AppInsightsService } from './app-insights.service';
import { ILoggedInUser, IGeoLocationLoginModel, IStoreGroupUserListModel } from '../Shared/models/ProspectModel';
import { AppService } from './app.service';
import { EMFAType } from '../Shared/enum';

@Injectable({
   providedIn: 'root',
})

export class UserService {
   private baseURL = this.appService.config.apiBaseUrl;
   bearerToken;
   fingerPrintUserId = 1;
   isFingerPrintLogin = false;

   private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

   constructor(private http: HttpClient,
      private appinsights: AppInsightsService,
      private appService: AppService) { }

   getPrintUser(userId: number): Observable<string> {
      return this.http.get<string>(
         `${this.baseURL}/user/GetPrintUser?userId=${userId}`,
         { observe: 'response' })
         .pipe(
            map(result => {
               const printContent = result.body as string;
               return printContent;
            }
            ),
            catchError(this.handleError)
         );
   }

   getIsUserGroupCallCenter(groupId): Observable<boolean> {
      return this.http.get<boolean>(
         `${this.baseURL}/user/GetIsUserGroupCallCenter?groupId=${groupId}`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const isCallCenterGroup = res.body as boolean;
               return isCallCenterGroup;
            }),
            catchError(this.handleError)
         );
   }

   getIsLicenseCountExceeded(): Observable<boolean> {
      return this.http.get<IUserLicenseExceeds>(
         `${this.baseURL}/user/IsLicenseCountExceeded`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const userDetails = res.body as IUserLicenseExceeds;
               return userDetails.Exceeds;
            }),
            catchError(this.handleError)
         );
   }

   getUsers(): Observable<IUserList[]> {
      return this.http.get<IUserList[]>(
         `${this.baseURL}/user/GetUsers`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const users = res.body as IUserList[];
               return users;
            }),
            catchError(this.handleError)
         );
   }

   GetAllUsers(): Observable<IUserList[]> {
      return this.http.get<IUserList[]>(
         `${this.baseURL}/user/GetAllUsers`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const users = res.body as IUserList[];
               return users;
            }),
            catchError(this.handleError)
         );
   }

   GetFNameOrLNameOfUsers(): Observable<IUserList[]> {
      return this.http.get<IUserList[]>(
         `${this.baseURL}/user/GetFNameOrLNameOfUsers`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const users = res.body as IUserList[];
               return users;
            }),
            catchError(this.handleError)
         );
   }

   GetLookUpTaskUsers(): Observable<IUserList[]> {
      return this.http.get<IUserList[]>(
         `${this.baseURL}/BatchTasks/GetLookUpTaskUsers`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const users = res.body as IUserList[];
               return users;
            }),
            catchError(this.handleError)
         );
   }

   getavailableUsers(): Observable<IUserList[]> {
      return this.http.get<IUserList[]>(
         `${this.baseURL}/DashboardSettings/GetAvailableUsers`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const users = res.body as IUserList[];
               return users;
            }),
            catchError(this.handleError)
         );
   }


   getUserDetail(userId: number): Observable<IUser> {
      return this.http.get<IUser>(

         `${this.baseURL}/user/GetUserDetail?userId=${userId}`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const userDetails = res.body as IUser;
               return userDetails;
            }),
            catchError(this.handleError)
         );
   }

   updateUser(user: IUser): Observable<ISaveUserResponse> {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      let url = '';
      if (user.Id === 0) {
         url = `${this.baseURL}/user/CreateUser`;
      } else {
         url = `${this.baseURL}/user/UpdateUserDetails`;
      }
      return this.http.post<ISaveUserResponse>(url, user, options)
         .pipe(
            map(res => {
               return res;
            }),
            catchError(this.handleError)
         );
   }


   getIsUserNameExist(username: string): Observable<any> {
      return this.http.get<IUserNameExistModel>(
         `${this.baseURL}/user/GetIsUserNameExist?username=${username}`,
         { observe: 'response' })
         .pipe(
            map(result => {
               const res = result.body as IUserNameExistModel;
               return res;
            }
            ),
            catchError(this.handleError)
         );
   }

   getIsUserInitialsExist(initials: string): Observable<boolean> {
      return this.http.get<any>(
         `${this.baseURL}/user/GetIsInitialsExist?initials=${initials}`,
         { observe: 'response' })
         .pipe(
            map(result => {
               let isExist: boolean;
               const NameExist = result.body as any;
               if ((typeof NameExist === 'undefined') || (NameExist === null)) {
                  isExist = false;
                  return isExist;
               } else {
                  return NameExist;
               }
            }),
            catchError(this.handleError)
         );
   }

   deleteUser(userId: number, authenticationId: string) {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      return this.http.post<ISaveDeleteResponse>(
         `${this.baseURL}/user/DeleteUser?userId=${userId}&authenticationId=${authenticationId}`, options);
   }

   updateLoginDate(): Observable<ISaveDeleteResponse> {
      return this.http.get<ISaveDeleteResponse>(
         `${this.baseURL}/user/SaveUserLoginDateTime`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const response = res.body as ISaveDeleteResponse;
               return response;
            }),
            catchError(this.handleError)
         );

   }

   SaveLastAccessedFacility(facilityId: number) {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      return this.http.post<ISaveDeleteResponse>(
         `${this.baseURL}/user/SaveLastAccessedFacility?facilityId=${facilityId}`, options);
   }

   UpdateUserPassword(userId: number, authenticationId: string, newPassword: string) {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      const url = `${this.baseURL}/user/UpdateUserPassword`;
      var obj = {
         UserId: userId,
         AuthenticationId: authenticationId,
         NewPassword: newPassword
      };
      return this.http.post<ISaveDeleteResponse>(url, obj, options)
         .pipe(
            map(res => {
               return res as ISaveDeleteResponse;
            }),
            catchError(this.handleError)
         );
   }

   getLoggedInUserDetails(): Observable<ILoggedInUser> {
      let headers;
      if (this.isFingerPrintLogin) {
         headers = new HttpHeaders({ 'Content-Type': 'application/json', 'fingerPrintUserId': `${this.fingerPrintUserId}` });
      } else {
         headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      }

      return this.http.get<ILoggedInUser>(
         `${this.baseURL}/user/GetLoggedInUserDetails`,
         { headers, observe: 'response' })
         .pipe(
            map(res => {
               const loggedInUser = res.body as ILoggedInUser;
               return loggedInUser;
            }),
            catchError(this.handleError)
         );
   }
   getProcessDayEnd(): Observable<IApiResponse> {
      return this.http.get<IApiResponse>(
         `${this.baseURL}/DayEnd/ProcessDayEnd`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const users = res.body as IApiResponse;
               return users;
            }),
            catchError(this.handleError)
         );
   }
   getCheckDayEndInProgress(facilityId: number): Observable<any> {

      //     var bearer = "Bearer " + this.bearerToken;
      //    let headers = new HttpHeaders({ 'Authorization': bearer  }) ;
      return this.http.get<any[]>(
         `${this.baseURL}/DayEnd/CheckDayEndInProgress?facilityId=${facilityId}`,
         { observe: 'response' })
         .pipe(
            map(res => {
               return res.body as any;
            }),
            catchError(this.handleError)
         );
   }

   GetLastDayendProcessedDate(facilityId: number, checkMiningCompletion: boolean): Observable<any> {
      return this.http.get<any[]>(
         `${this.baseURL}/DayEnd/GetLastDayendProcessedDate?facilityId=${facilityId}&checkMiningCompletion=${true}`,
         { observe: 'response' })
         .pipe(
            map(res => {
               return res.body as any;
            }),
            catchError(this.handleError)
         );
   }

   getStorageFacilityByLatitudeAndLongitude(latitude, longitude): Observable<IGeoLocationLoginModel> {
      return this.http.get<IGeoLocationLoginModel>(
         `${this.baseURL}/Store/GetStorageFacilityByLatitudeAndLongitude?strLatitude=${latitude}&strLongitude=${longitude}`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const loggedInUser = res.body as IGeoLocationLoginModel;
               return loggedInUser;
            }),
            catchError(this.handleError)
         );
   }

   getStoreGroupListForUsers() {
      return this.http.get<IStoreGroupUserListModel[]>(
         `${this.baseURL}/user/GetStoreGroupListForUsers`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const loggedInUser = res.body as IStoreGroupUserListModel[];
               return loggedInUser;
            }),
            catchError(this.handleError)
         );
   }


   GetThirdPartyUsersAcessList(): Observable<any[]> { // model 
      return this.http.get<any[]>(
         `${this.baseURL}/user/GetThirdPartyUsersAcessList`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const users = res.body as any[];
               return users;
            }),
            catchError(this.handleError)
         );
   }

   GetThirdPartyUsersDetails(userId: number): Observable<IThirdPartyUserModel> {
      return this.http.get<IThirdPartyUserModel>(
         `${this.baseURL}/user/GetThirdPartyUsersDetails?userId=${userId}`,
         { observe: 'response' })
         .pipe(
            map(result => {
               const res = result.body as IThirdPartyUserModel;
               return res;
            }
            ),
            catchError(this.handleError)
         );
   }

   UpdateThirdPartyUser(details: IThirdPartyUserModel): Observable<boolean> {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      let url = '';
      url = `${this.baseURL}/user/UpdateThirdPartyUser`;
      return this.http.post<ISaveDeleteResponse>(url, details, options)
         .pipe(
            map(res => {
               return res.Success;
            }),
            catchError(this.handleError)
         );
   }

   GetExcludedThirdPartyUsersForReports(): Observable<any[]> {
      return this.http.get<any[]>(
         `${this.baseURL}/User/GetExcludedThirdPartyUsersForReports`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const users = res.body as any[];
               return users;
            }),
            catchError(this.handleError)
         );
   }

   GetApiList(): Observable<any[]> {
      return this.http.get<any[]>(
         `${this.baseURL}/user/ApiList`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const users = res.body as any[];
               return users;
            }),
            catchError(this.handleError)
         );
   }

   GetMFASettings(): Observable<IMFASettings> {
      return this.http.get<IMFASettings>(
         `${this.baseURL}/B2CSettings/GetMFASettings`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const settings = res.body as IMFASettings
               return settings;
            }),
            catchError(this.handleError)
         );
   }

   NamedLocations(): Observable<any[]> {
      return this.http.get<any[]>(
         `${this.baseURL}/B2CSettings/NamedLocations`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const locations = res.body as any[];
               return locations;
            }),
            catchError(this.handleError)
         );
   }

   ConditionalAccessPolicies(): Observable<any[]> {
      return this.http.get<any[]>(
         `${this.baseURL}/B2CSettings/ConditionalAccessPolicies`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const users = res.body as any[];
               return users;
            }),
            catchError(this.handleError)
         );
   }

   SaveMFASettings(details: IMFASettings): Observable<boolean> {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      let url = '';
      url = `${this.baseURL}/B2CSettings/SaveMFASettings`;
      return this.http.post<ISaveDeleteResponse>(url, details, options)
         .pipe(
            map(res => {
               return res.Success;
            }),
            catchError(this.handleError)
         );
   }

   UpdateConditionalAccessPolicy(policyId: string, details: any): Observable<any> {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }; //  observe: 'response' as const, responseType: 'text' as const
      let url = '';
      url = `${this.baseURL}/B2CSettings/UpdateConditionalAccessPolicy?policyId=${policyId}`;
      return this.http.patch<any>(url, details, options)
         .pipe(
            map(res => {
               return res;
            }),
            catchError(this.handleError)
         );
   }

   UpdateUserMFA(objectId: string, mfaType: number, mfaValue: string) {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      mfaValue = mfaValue == "" && mfaType !== EMFAType.SMS ? null : mfaValue;
      return this.http.patch<boolean>(
         `${this.baseURL}/B2CSettings/UpdateUserMFA?objectId=${objectId}&mfaType=${mfaType}&mfaValue=${mfaValue}`, options);
   }

   CreateOrUpdateNamedLocation(details: any, isNew, namedLocationId): Observable<any> {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      let url = '';
      if (isNew) {
         url = `${this.baseURL}/B2CSettings/CreateNamedLocation`;
         return this.http.post<any>(url, details, options)
            .pipe(
               map(res => {
                  return res;
               }),
               catchError(this.handleError)
            );
      } else {
         url = `${this.baseURL}/B2CSettings/UpdateNamedLocation?namedLocationId=${namedLocationId}`;
         return this.http.patch<any>(url, details, options)
            .pipe(
               map(res => {
                  return res;
               }),
               catchError(this.handleError)
            );
      }
   }

   deleteNamedLocation(namedLocationId: any) {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      let url = `${this.baseURL}/B2CSettings/DeleteNamedLocation?namedLocationId=${namedLocationId}`;
      return this.http.delete<any>(url, options)
         .pipe(
            map(res => {
               return res;
            }),
         );
   }

   ListRiskyUsers(): Observable<IRiskyUser[]> {
      return this.http.get<IRiskyUser[]>(
         `${this.baseURL}/B2CSettings/ListRiskyUsers`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const userRisk = res.body as IRiskyUser[];
               return userRisk;
            }),
            catchError(this.handleError)
         );
   }

   ConfirmUsersCompromised(inputData: string[]): Observable<boolean> {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      let url = `${this.baseURL}/B2CSettings/ConfirmUsersCompromised`;
      return this.http.post<boolean>(url, inputData, options)
         .pipe(
            map(res => {
               return res;
            }),
            catchError(this.handleError)
         );
   }

   DismissUsersRisk(inputData: string[]): Observable<boolean> {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      let url = `${this.baseURL}/B2CSettings/DismissUsersRisk`;
      return this.http.post<boolean>(url, inputData, options)
         .pipe(
            map(res => {
               return res;
            }),
            catchError(this.handleError)
         );
   }

   updateResetPassword(objectId: string): Observable<boolean> {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      return this.http.post<boolean>(
         `${this.baseURL}/User/ForcePasswordChange?objectId=${objectId}`, options);
   }

   BlockSignIn(objectId: string, blockSignIn: boolean): Observable<boolean> {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      return this.http.post<boolean>(
         `${this.baseURL}/User/BlockSignIn?objectId=${objectId}&blockSignIn=${blockSignIn}`, options);
   }

   GetRiskyUser(objectId: string): Observable<IRiskyUser> {
      return this.http.get<IRiskyUser>(
         `${this.baseURL}/B2CSettings/GetRiskyUser?objectId=${objectId}`,
         { observe: 'response' })
         .pipe(
            map(result => {
               const res = result.body as IRiskyUser;
               return res;
            }
            ),
            catchError(this.handleError)
         );
   }

   GetPasswordComplexity(): Observable<IRiskyUser[]> {
      return this.http.get<IRiskyUser[]>(
         `${this.baseURL}/B2CSettings/GetPasswordComplexity`,
         { observe: 'response' })
         .pipe(
            map(res => {
               const passwordComplexity = res.body;
               return passwordComplexity;
            }),
            catchError(this.handleError)
         );
   }

   private handleError(error: HttpErrorResponse) {
      if (error.error instanceof Error) {
         const errMessage = error.error.message;
         this.appinsights.logEvent(errMessage);
         return throwError(errMessage);
      }
      return throwError(error || 'Node.js server error');
   }

   getAllCountriesWithCodes() {
      let countries = [
         {
            "Name": "Afghanistan",
            "Code": "AF"
         },
         {
            "Name": "Albania",
            "Code": "AL"
         },
         {
            "Name": "Algeria",
            "Code": "DZ"
         },
         {
            "Name": "Andorra",
            "Code": "AD"
         },
         {
            "Name": "Angola",
            "Code": "AO"
         },
         {
            "Name": "Antigua and Barbuda",
            "Code": "AG"
         },
         {
            "Name": "Argentina",
            "Code": "AR"
         },
         {
            "Name": "Armenia",
            "Code": "AM"
         },
         {
            "Name": "Australia",
            "Code": "AU"
         },
         {
            "Name": "Austria",
            "Code": "AT"
         },
         {
            "Name": "Azerbaijan",
            "Code": "AZ"
         },
         {
            "Name": "Bahamas",
            "Code": "BS"
         },
         {
            "Name": "Bahrain",
            "Code": "BH"
         },
         {
            "Name": "Bangladesh",
            "Code": "BD"
         },
         {
            "Name": "Barbados",
            "Code": "BB"
         },
         {
            "Name": "Belarus",
            "Code": "BY"
         },
         {
            "Name": "Belgium",
            "Code": "BE"
         },
         {
            "Name": "Belize",
            "Code": "BZ"
         },
         {
            "Name": "Benin",
            "Code": "BJ"
         },
         {
            "Name": "Bhutan",
            "Code": "BT"
         },
         {
            "Name": "Bolivia",
            "Code": "BO"
         },
         {
            "Name": "Bosnia and Herzegovina",
            "Code": "BA"
         },
         {
            "Name": "Botswana",
            "Code": "BW"
         },
         {
            "Name": "Brazil",
            "Code": "BR"
         },
         {
            "Name": "Brunei",
            "Code": "BN"
         },
         {
            "Name": "Bulgaria",
            "Code": "BG"
         },
         {
            "Name": "Burkina Faso",
            "Code": "BF"
         },
         {
            "Name": "Burundi",
            "Code": "BI"
         },
         {
            "Name": "Cabo Verde",
            "Code": "CV"
         },
         {
            "Name": "Cambodia",
            "Code": "KH"
         },
         {
            "Name": "Cameroon",
            "Code": "CM"
         },
         {
            "Name": "Canada",
            "Code": "CA"
         },
         {
            "Name": "Central African Republic",
            "Code": "CF"
         },
         {
            "Name": "Chad",
            "Code": "TD"
         },
         {
            "Name": "Chile",
            "Code": "CL"
         },
         {
            "Name": "China",
            "Code": "CN"
         },
         {
            "Name": "Colombia",
            "Code": "CO"
         },
         {
            "Name": "Comoros",
            "Code": "KM"
         },
         {
            "Name": "Congo, Democratic Republic of the",
            "Code": "CD"
         },
         {
            "Name": "Congo, Republic of the",
            "Code": "CG"
         },
         {
            "Name": "Costa Rica",
            "Code": "CR"
         },
         {
            "Name": "Cote d'Ivoire",
            "Code": "CI"
         },
         {
            "Name": "Croatia",
            "Code": "HR"
         },
         {
            "Name": "Cuba",
            "Code": "CU"
         },
         {
            "Name": "Cyprus",
            "Code": "CY"
         },
         {
            "Name": "Czech Republic",
            "Code": "CZ"
         },
         {
            "Name": "Denmark",
            "Code": "DK"
         },
         {
            "Name": "Djibouti",
            "Code": "DJ"
         },
         {
            "Name": "Dominica",
            "Code": "DM"
         },
         {
            "Name": "Dominican Republic",
            "Code": "DO"
         },
         {
            "Name": "East Timor (Timor-Leste)",
            "Code": "TL"
         },
         {
            "Name": "Ecuador",
            "Code": "EC"
         },
         {
            "Name": "Egypt",
            "Code": "EG"
         },
         {
            "Name": "El Salvador",
            "Code": "SV"
         },
         {
            "Name": "Equatorial Guinea",
            "Code": "GQ"
         },
         {
            "Name": "Eritrea",
            "Code": "ER"
         },
         {
            "Name": "Estonia",
            "Code": "EE"
         },
         {
            "Name": "Eswatini",
            "Code": "SZ"
         },
         {
            "Name": "Ethiopia",
            "Code": "ET"
         },
         {
            "Name": "Fiji",
            "Code": "FJ"
         },
         {
            "Name": "Finland",
            "Code": "FI"
         },
         {
            "Name": "France",
            "Code": "FR"
         },
         {
            "Name": "Gabon",
            "Code": "GA"
         },
         {
            "Name": "Gambia",
            "Code": "GM"
         },
         {
            "Name": "Georgia",
            "Code": "GE"
         },
         {
            "Name": "Germany",
            "Code": "DE"
         },
         {
            "Name": "Ghana",
            "Code": "GH"
         },
         {
            "Name": "Greece",
            "Code": "GR"
         },
         {
            "Name": "Grenada",
            "Code": "GD"
         },
         {
            "Name": "Guatemala",
            "Code": "GT"
         },
         {
            "Name": "Guinea",
            "Code": "GN"
         },
         {
            "Name": "Guinea-Bissau",
            "Code": "GW"
         },
         {
            "Name": "Guyana",
            "Code": "GY"
         },
         {
            "Name": "Haiti",
            "Code": "HT"
         },
         {
            "Name": "Honduras",
            "Code": "HN"
         },
         {
            "Name": "Hungary",
            "Code": "HU"
         },
         {
            "Name": "Iceland",
            "Code": "IS"
         },
         {
            "Name": "India",
            "Code": "IN"
         },
         {
            "Name": "Indonesia",
            "Code": "ID"
         },
         {
            "Name": "Iran",
            "Code": "IR"
         },
         {
            "Name": "Iraq",
            "Code": "IQ"
         },
         {
            "Name": "Ireland",
            "Code": "IE"
         },
         {
            "Name": "Israel",
            "Code": "IL"
         },
         {
            "Name": "Italy",
            "Code": "IT"
         },
         {
            "Name": "Jamaica",
            "Code": "JM"
         },
         {
            "Name": "Japan",
            "Code": "JP"
         },
         {
            "Name": "Jordan",
            "Code": "JO"
         },
         {
            "Name": "Kazakhstan",
            "Code": "KZ"
         },
         {
            "Name": "Kenya",
            "Code": "KE"
         },
         {
            "Name": "Kiribati",
            "Code": "KI"
         },
         {
            "Name": "Korea, North",
            "Code": "KP"
         },
         {
            "Name": "Korea, South",
            "Code": "KR"
         },
         {
            "Name": "Kosovo",
            "Code": "XK"
         },
         {
            "Name": "Kuwait",
            "Code": "KW"
         },
         {
            "Name": "Kyrgyzstan",
            "Code": "KG"
         },
         {
            "Name": "Laos",
            "Code": "LA"
         },
         {
            "Name": "Latvia",
            "Code": "LV"
         },
         {
            "Name": "Lebanon",
            "Code": "LB"
         },
         {
            "Name": "Lesotho",
            "Code": "LS"
         },
         {
            "Name": "Liberia",
            "Code": "LR"
         },
         {
            "Name": "Libya",
            "Code": "LY"
         },
         {
            "Name": "Liechtenstein",
            "Code": "LI"
         },
         {
            "Name": "Lithuania",
            "Code": "LT"
         },
         {
            "Name": "Luxembourg",
            "Code": "LU"
         },
         {
            "Name": "Madagascar",
            "Code": "MG"
         },
         {
            "Name": "Malawi",
            "Code": "MW"
         },
         {
            "Name": "Malaysia",
            "Code": "MY"
         },
         {
            "Name": "Maldives",
            "Code": "MV"
         },
         {
            "Name": "Mali",
            "Code": "ML"
         },
         {
            "Name": "Malta",
            "Code": "MT"
         },
         {
            "Name": "Marshall Islands",
            "Code": "MH"
         },
         {
            "Name": "Mauritania",
            "Code": "MR"
         },
         {
            "Name": "Mauritius",
            "Code": "MU"
         },
         {
            "Name": "Mexico",
            "Code": "MX"
         },
         {
            "Name": "Micronesia",
            "Code": "FM"
         },
         {
            "Name": "Moldova",
            "Code": "MD"
         },
         {
            "Name": "Monaco",
            "Code": "MC"
         },
         {
            "Name": "Mongolia",
            "Code": "MN"
         },
         {
            "Name": "Montenegro",
            "Code": "ME"
         },
         {
            "Name": "Morocco",
            "Code": "MA"
         },
         {
            "Name": "Mozambique",
            "Code": "MZ"
         },
         {
            "Name": "Myanmar (Burma)",
            "Code": "MM"
         },
         {
            "Name": "Namibia",
            "Code": "NA"
         },
         {
            "Name": "Nauru",
            "Code": "NR"
         },
         {
            "Name": "Nepal",
            "Code": "NP"
         },
         {
            "Name": "Netherlands",
            "Code": "NL"
         },
         {
            "Name": "New Zealand",
            "Code": "NZ"
         },
         {
            "Name": "Nicaragua",
            "Code": "NI"
         },
         {
            "Name": "Niger",
            "Code": "NE"
         },
         {
            "Name": "Nigeria",
            "Code": "NG"
         },
         {
            "Name": "North Macedonia (Macedonia)",
            "Code": "MK"
         },
         {
            "Name": "Norway",
            "Code": "NO"
         },
         {
            "Name": "Oman",
            "Code": "OM"
         },
         {
            "Name": "Pakistan",
            "Code": "PK"
         },
         {
            "Name": "Palau",
            "Code": "PW"
         },
         {
            "Name": "Palestine",
            "Code": "PS"
         },
         {
            "Name": "Panama",
            "Code": "PA"
         },
         {
            "Name": "Papua New Guinea",
            "Code": "PG"
         },
         {
            "Name": "Paraguay",
            "Code": "PY"
         },
         {
            "Name": "Peru",
            "Code": "PE"
         },
         {
            "Name": "Philippines",
            "Code": "PH"
         },
         {
            "Name": "Poland",
            "Code": "PL"
         },
         {
            "Name": "Portugal",
            "Code": "PT"
         },
         {
            "Name": "Qatar",
            "Code": "QA"
         },
         {
            "Name": "Romania",
            "Code": "RO"
         },
         {
            "Name": "Russia",
            "Code": "RU"
         },
         {
            "Name": "Rwanda",
            "Code": "RW"
         },
         {
            "Name": "Saint Kitts and Nevis",
            "Code": "KN"
         },
         {
            "Name": "Saint Lucia",
            "Code": "LC"
         },
         {
            "Name": "Saint Vincent and the Grenadines",
            "Code": "VC"
         },
         {
            "Name": "Samoa",
            "Code": "WS"
         },
         {
            "Name": "San Marino",
            "Code": "SM"
         },
         {
            "Name": "Sao Tome and Principe",
            "Code": "ST"
         },
         {
            "Name": "Saudi Arabia",
            "Code": "SA"
         },
         {
            "Name": "Senegal",
            "Code": "SN"
         },
         {
            "Name": "Serbia",
            "Code": "RS"
         },
         {
            "Name": "Seychelles",
            "Code": "SC"
         },
         {
            "Name": "Sierra Leone",
            "Code": "SL"
         },
         {
            "Name": "Singapore",
            "Code": "SG"
         },
         {
            "Name": "Slovakia",
            "Code": "SK"
         },
         {
            "Name": "Slovenia",
            "Code": "SI"
         },
         {
            "Name": "Solomon Islands",
            "Code": "SB"
         },
         {
            "Name": "Somalia",
            "Code": "SO"
         },
         {
            "Name": "South Africa",
            "Code": "ZA"
         },
         {
            "Name": "South Sudan",
            "Code": "SS"
         },
         {
            "Name": "Spain",
            "Code": "ES"
         },
         {
            "Name": "Sri Lanka",
            "Code": "LK"
         },
         {
            "Name": "Sudan",
            "Code": "SD"
         },
         {
            "Name": "Suriname",
            "Code": "SR"
         },
         {
            "Name": "Sweden",
            "Code": "SE"
         },
         {
            "Name": "Switzerland",
            "Code": "CH"
         },
         {
            "Name": "Syria",
            "Code": "SY"
         },
         {
            "Name": "Taiwan",
            "Code": "TW"
         },
         {
            "Name": "Tajikistan",
            "Code": "TJ"
         },
         {
            "Name": "Tanzania",
            "Code": "TZ"
         },
         {
            "Name": "Thailand",
            "Code": "TH"
         },
         {
            "Name": "Togo",
            "Code": "TG"
         },
         {
            "Name": "Tonga",
            "Code": "TO"
         },
         {
            "Name": "Trinidad and Tobago",
            "Code": "TT"
         },
         {
            "Name": "Tunisia",
            "Code": "TN"
         },
         {
            "Name": "Turkey",
            "Code": "TR"
         },
         {
            "Name": "Turkmenistan",
            "Code": "TM"
         },
         {
            "Name": "Tuvalu",
            "Code": "TV"
         },
         {
            "Name": "Uganda",
            "Code": "UG"
         },
         {
            "Name": "Ukraine",
            "Code": "UA"
         },
         {
            "Name": "United Arab Emirates",
            "Code": "AE"
         },
         {
            "Name": "United Kingdom",
            "Code": "GB"
         },
         {
            "Name": "United States",
            "Code": "US"
         },
         {
            "Name": "Uruguay",
            "Code": "UY"
         },
         {
            "Name": "Uzbekistan",
            "Code": "UZ"
         },
         {
            "Name": "Vanuatu",
            "Code": "VU"
         },
         {
            "Name": "Vatican City",
            "Code": "VA"
         },
         {
            "Name": "Venezuela",
            "Code": "VE"
         },
         {
            "Name": "Vietnam",
            "Code": "VN"
         },
         {
            "Name": "Yemen",
            "Code": "YE"
         },
         {
            "Name": "Zambia",
            "Code": "ZM"
         },
         {
            "Name": "Zimbabwe",
            "Code": "ZW"
         }
      ];
      return countries;
   }

}
